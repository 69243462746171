import { Icon, type SizeProp } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { handleKeyboardAction } from '@components/utilities/handleKeyboardAction'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { ColorGroup } from '@lib/colors/colors'
import { getColorVariant } from '@lib/utilities/colors/getColorVariant'
import truncateString from '@lib/utilities/text/truncateString'
import type { MouseEvent, ReactNode } from 'react'
import type { TextStyle } from '../Text/fontStyles'

type Variant = 'accent' | 'filled' | 'neutral' | 'outlined'

export interface BadgeProps {
  className?: string
  colorGroup?: ColorGroup
  icon?: IconDefinition
  iconClassName?: string
  iconSize?: SizeProp
  label: ReactNode
  labelPreface?: ReactNode
  linkOnClick?: () => void
  maxLabelLength?: number
  onClick?: () => void
  strong?: boolean
  styleName?: TextStyle
  textClassName?: string
  variant?: Variant
}

export const Badge = ({
  className,
  colorGroup = 'warm',
  icon,
  iconClassName,
  iconSize,
  label,
  labelPreface,
  linkOnClick,
  maxLabelLength,
  onClick,
  strong = true,
  styleName = 'p-tiny',
  textClassName,
  variant = 'filled',
}: BadgeProps) => {
  const isLabelString = typeof label === 'string'
  const labelToDisplay = isLabelString
    ? truncateString(label, maxLabelLength)
    : label

  const colorClasses = getColorVariant(colorGroup, variant)

  const outline = variant === 'outlined' ? 'outline' : ''

  const badgeStyles = classMerge(
    className,
    colorClasses,
    'inline-block h-fit whitespace-nowrap rounded-full px-3 py-1',
    outline,
  )

  const style = strong ? 'p-tiny-strong' : styleName

  // Determine whether to use a button or div based on onClick presence
  const Element = onClick ? 'button' : 'div'

  return (
    <Element
      className={badgeStyles}
      onClick={onClick}
      onKeyDown={onClick ? (e) => handleKeyboardAction(e, onClick) : undefined}
      tabIndex={onClick ? 0 : undefined}
      type={onClick ? 'button' : undefined}
    >
      <div className='flex flex-row items-center'>
        {icon && (
          <Icon
            aria-hidden='true'
            className={classMerge('mr-1', iconClassName)}
            icon={icon}
            size={iconSize}
          />
        )}
        {labelPreface && (
          <Text className='mr-[2px]' styleName='p-xtiny' value={labelPreface} />
        )}
        <Text
          className={classMerge(colorClasses, textClassName)}
          styleName={style}
          value={labelToDisplay}
        />
        {linkOnClick && (
          <Text
            className={classMerge(
              'ml-2 cursor-pointer text-xs text-blue500 underline underline-offset-4',
            )}
            onClick={(e: MouseEvent) => {
              e.stopPropagation()
              linkOnClick?.()
            }}
            onKeyDown={(e) => handleKeyboardAction(e, linkOnClick)}
            role='button'
            styleName={styleName}
            tabIndex={0}
          >
            View More
          </Text>
        )}
      </div>
    </Element>
  )
}
