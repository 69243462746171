import isAuthoritativeCondition, {
  isModuleCondition,
} from '@modules/conditions/helpers/isAuthoritativeCondition'

export const FALLBACK_CONDITION_NAME = 'Condition'

interface Condition {
  colloquialTermStripped: string | null
  id: number
  meshId: string | null
  name: string
  umlsTreeNumber: string | null
}

interface TrialCondition {
  condition: Condition
  inConditionsModule: boolean
  isPrimaryCondition: boolean | null
  trialEditId: number | null
}

interface Trial {
  conditions: TrialCondition[]
}

/**
 * Find the condition which was marked as primary through the NLP classification script.
 * If no primary condition exists, fallback to any authoritative condition
 * (i.e. inputted manually by the site coordinator/researcher and matched to a mesh id).
 * If no authoritative condition exists, fallback to any condition in the conditions module
 * (i.e. inputted manually by the site coordinator/researcher).
 * If no condition exists in the conditions module, fallback to the first condition in the list.
 * Finally, if no conditions exist at all, fallback to 'Condition'.
 * @param trial Trial to extract primary condition from.
 */
export const getPrimaryConditionFromTrial = (trial: Trial) => {
  const primaryCondition = getPrimaryConditionObjectFromTrial(trial)

  return (
    primaryCondition?.colloquialTermStripped ??
    primaryCondition?.name ??
    FALLBACK_CONDITION_NAME
  )
}

export function getPrimaryConditionObjectFromTrial(trial: Trial) {
  const primaryCondition = trial.conditions
    .filter(isModuleCondition) // There is no reason for the primary condition to not be in the conditions module.
    .find((c) => c.isPrimaryCondition)?.condition

  const fallbackAuthoritativeCondition = trial.conditions.find(
    isAuthoritativeCondition,
  )?.condition

  const fallbackModuleCondition =
    trial.conditions.find(isModuleCondition)?.condition

  return (
    primaryCondition ??
    fallbackAuthoritativeCondition ??
    fallbackModuleCondition ??
    trial.conditions[0]?.condition!
  )
}
