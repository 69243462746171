export default function truncateString(s: string, maxLength?: number): string {
  if (!maxLength || s.length <= maxLength) {
    return s
  }

  // Find the last space before the maxLength
  const lastSpaceIndex = s.lastIndexOf(' ', maxLength)

  // If no space found or it's too early in the string, just cut at maxLength
  if (lastSpaceIndex === -1 || lastSpaceIndex < maxLength / 2) {
    return `${s.substring(0, maxLength)}…`
  }

  // Otherwise truncate at the last space and add ellipsis
  return `${s.substring(0, lastSpaceIndex)}…`
}
