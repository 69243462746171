import { cloudflareImageUrl } from '@lib/cloudflare/cloudflareImageUrl'
import {
  defaultPublicOpenGraph,
  defaultSSPOpenGraph,
} from '@modules/images/shared/openGraphImages'
import Head from 'next/head'

type OpenGraphCardProps = {
  description: string
  imageDescription?: string
  imageUrl?: string
  title: string
  url?: string
}

export default function OpenGraphCard({
  description,
  imageDescription,
  imageUrl,
  title,
  url,
}: OpenGraphCardProps) {
  imageUrl = imageUrl ?? getDefaultImageUrl(url)

  return (
    <Head>
      <meta content={description} property='og:description' />
      <meta content='website' property='og:type' />
      <meta content={imageUrl} property='og:image' />
      <meta content={imageDescription ?? description} property='og:image:alt' />
      <meta content={imageUrl} name='twitter:image' />
      <meta content={title} property='og:title' />
      <meta content='Power' property='og:site_name' />
      {url && (
        <>
          <meta content={url} property='og:url' />
          <meta content={url} property='twitter:url' />
        </>
      )}
      <meta content='summary_large_image' name='twitter:card' />
      <meta content='withpower.com' property='twitter:domain' />
      <meta content={title} name='twitter:title' />
      <meta content={description} name='twitter:description' />
    </Head>
  )
}

// Different image for SSP vs regular
function getDefaultImageUrl(url: string | undefined) {
  const imageUrl = url?.includes('/s/')
    ? defaultSSPOpenGraph
    : defaultPublicOpenGraph
  return cloudflareImageUrl(imageUrl)
}
